
import { Link } from 'react-router-dom';
import './App.css';
import logo from './magic.png'
import { useEffect, useState } from 'react';

/*------------------------------------*/
;
/*------------------------------------*/
function Rcp() {
   // const [categories, setCategories] = useState([]);
  //  const [content, setContent] = useState('https://admin.magic20.co.in/Pages/PrivacyPolicy.html');


    function category() {
        fetch('https://api.magic20.co.in/LandingPage/GetCategories').then((respons) => {
            respons.json().then((result) => {
               // setCategories(result.data);
            })
        });
    }

    const [data, setData] = useState()

    function link() {
        fetch('https://admin.magic20.co.in/staticpages/TermsOfRefundAndCancellationUse').then((respons) => {
            respons.text().then((text) => {
                console.log(text);
                setData(text)
                // Now you can do whatever you want with the text, such as inserting it into an HTML element
                // document.getElementById('content').innerText = text;
            }).catch((e) => {
                console.log(e);
            });
        })
    }


    // setTimeout(() => {
    //     document.getElementById('content').innerHTML = '<object type="type/html" data="https://admin.magic20.co.in/Pages/PrivacyPolicy.html" ></object>';
    // }, 500)
    function load_home() {
        // document.getElementById("content").innerHTML = `<object type="text/html" data="${'https://admin.magic20.co.in/Pages/PrivacyPolicy.html'}" ></object>`;
        document.getElementById("content").innerHTML = ` <iframe src="${'https://admin.magic20.co.in/Pages/RefundAndCancellation.html'}" title="" style="width: 100%;height: 500px;border: none;font-family:'Roboto', sans-serif;"></iframe> `;
    }

    useEffect(() => {
        category();
        link()
        load_home()
        window.scrollTo(0, 100);
    }, [])

    // console.warn("Book",book)
    return (
        <div className="App">
            <header className="appie-header-area appie-header-2-area appie-sticky">
                <div className="container">
                    <div className="header-nav-box">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <Link to="/">
                                        <img src={logo} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">

                            </div>
                            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                    <a className="main-btn ml-30" href="https://web.magic20.co.in/#/intro" rel="noreferrer">Get Started</a>
                                    <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>



            <section class="appie-hero-area-2 mb-90">
                <div class="container">
                    <div class="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item">
                                <h3 className="title" style={{ color: '#0e1133' }}>Refund & Cancellation Policy</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" style={{ color: '#0e1133' }}>Home</li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ color: '#0e1133' }}>Refund & Cancellation Policy</li>
                                    </ol>
                                </nav>
                                <div className="thumb">
                                    <img src="assets/images/signup-thumb.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="appie-hero-thumb-3 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                    <img src="assets/images/hero-thumb-3-1.png" alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src="assets/images/shape/shape-9.png" alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src="assets/images/shape/shape-10.png" alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src="assets/images/shape/shape-11.png" alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src="assets/images/shape/shape-12.png" alt="" />
                </div>
            </section>



            <section className="appie-services-2-area pb-100" id="service">
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div id="content" dangerouslySetInnerHTML={{ __html: data }}></div>
                        </div>
                    </div>
                </div>
            </section>













        </div>
    );
}

export default Rcp;
