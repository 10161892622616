import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./home";
import Privacypolicy from "./privacypolicy";
import Tc from "./tc";
import Rcp from "./rcp";
import Footer from "./footer";
import PastEvents from "./News/pastEvent";
import EventDetails from "./News/eventDetails";
import UpcomingEvent from "./News/upcomingEvent";

/*------------------------------------*/
function App() {

    return (
        <>
            <Router>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="privacypolicy" element={<Privacypolicy />} />
                    <Route path="Terms&Conditions" element={<Tc />} />
                    <Route path="RefundAndCancellation" element={<Rcp />} />
                    <Route path="PastEvents" element={<PastEvents />} />
                    <Route path="upcomingEvent" element={<UpcomingEvent />} />
                    <Route path="eventDetails/:eventId" element={<EventDetails />} />
                    {/* <Route path="eventDetails" element={<EventDetails />} /> */}
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
