import http from "./httpcommon";
//import authHeader from "./authHeader";

const pastEvent = () => {
  return http.get(`PassedEvents`);
};
const upcomingEvents = () => {
  return http.get(`upcomingEvents`);
};
const eventsDetail = (eventId) => {
  return http.get(`EventsDetails/eventId=${eventId}`);
};
const eventsRegistration = (data) => {
  return http.post(`RegisterParticipant`,data);
};

const EventsApi = {
  pastEvent,
  upcomingEvents,
  eventsDetail,
  eventsRegistration
};

export default EventsApi;
