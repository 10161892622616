import axios from "axios";

const http = axios.create({
  baseURL: "https://api.magic20.co.in/LandingPage", // Replace this with your API base URL
  headers: {
    "Content-type": "application/json",
  },
});

export default http;
