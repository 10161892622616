
import './App.css';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
/*------------------------------------*/
import Header from './header';
/*------------------------------------*/
function Home() {
   // const [categories, setCategories] = useState([]);
 //   const [userCount, setUsecount] = useState([]);
   // const [authorCount, setAuthorcount] = useState([]);
   // const [categoryCount, setCategorycount] = useState([]);
   // const [upcomingPodcastCount, setUpcomingPodcastCount] = useState([]);
    const [book, setBook] = useState([]);
    const [bookList, setBookList] = useState([]);
    const [massage, setMassage] = useState([]);
    const [bookCount, setBookCount] = useState([]);
    const [price, setPrice] = useState([]);
    const [countrys, setCountrys] = useState([]);



    function uc() {
        fetch('https://api.magic20.co.in/LandingPage/GetCounts').then((respons) => {
            respons.json().then((result) => {
              //  setUsecount(result.userCount);
              //  setAuthorcount(result.authorCount);
              //  setCategorycount(result.categoryCount);
              //  setUpcomingPodcastCount(result.upcomingPodcastCount);
            })
        })
    }

    // function books() {
    //     fetch('https://api.magic20.co.in/LandingPage/Books').then((respons) => {
    //         respons.json().then((result) => {
    //             //   setBook(result.data);
    //             console.log(result.data)

    //             // setMassage(result.status.message)
    //             //setBookCount(result.status.totalRecords)
    //             console.log(massage)
    //         })
    //     });
    // }

    function allbooks() {
        fetch('https://api.magic20.co.in/LandingPage/GetAllbookByCategories').then((respons) => {
            respons.json().then((result) => {
                setBookList(result);


                setBook(result[0].books);
                setMassage(result[0].categoryName)
                setBookCount(result[0].books.length)
                // console.log(result.data)
                // setMassage(result.status.message)
                // setBookCount(result.status.totalRecords)
                console.log("GetAllbookByCategories", result)
            })
        });
    }

    function getPrice() {
        fetch('https://api.magic20.co.in/SubscriptionPlan/SubscriptionPlans').then((respons) => {
            respons.json().then((result) => {
                setPrice(result.data)
                console.log("result.data", result.data)
            })
        });
    }
    async function getUserCountryFromGeolocation() {
        // Check if the Geolocation API is available in the user's browser
        if ("geolocation" in navigator) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                const { latitude, longitude } = position.coords;
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
                );
                const data = await response.json();
                const country = data.address.country;
                setCountrys(country)
                console.log("User's country (Geolocation): ", country);
            } catch (error) {
                if (error.code === 1) {
                    console.error("User denied geolocation prompt.");
                } else {
                    console.error("Error getting user's location: ", error.message);
                }
                // If there's an error with Geolocation, try using the IP Geolocation method
                //  getUserCountryFromIP();
            }
        } else {
            console.error("Geolocation API is not supported in this browser.");
            // If Geolocation is not supported, try using the IP Geolocation method
            // getUserCountryFromIP();
        }
    }
    // let finalPrice = []
    // price.filter((items) => {
    //     // console.log("filter", items);
    //     if (items.item.currency === "INR") {
    //         console.log("if filter", items.item.currency);
    //         finalPrice += items
    //         // return items
    //     } else {
    //         console.log("filter", items.item.currency);
    //         finalPrice += items
    //     }
    // })
    // console.log("finalPrice", JSON.stringify(finalPrice));

    useEffect(() => {
        // category();
        uc();
        //  books();
        getPrice()
        getUserCountryFromGeolocation()
        allbooks()

    }, [])

    // console.warn("Book",book)
    return (
        <div className="App">
            <Header/>
            {/* <header className="appie-header-area appie-header-2-area appie-sticky">
                <div className="container-fluid">
                    <div className="header-nav-box">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <a href="!#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-1 col-sm-1 order-3 order-sm-2">

                            </div>
                            <div className="col-lg-5  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">
                                    <a className="main-btn ml-30" href="https://web.magic20.co.in/#/intro">Get Started</a>
                                    <a className="main-btn ml-30" href="#download">Download Our App</a>
                                    <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}


            <section class="appie-hero-area-2 mb-90">
                <div class="container">
                    <div class="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content-2">
                                <span>Free Books</span>
                                <h1 className="appie-title">படிப்பதற்கு நேரம் இல்லையா?  இனி படிக்க வேண்டாம் <span>கேளுங்க! மேஜிக் தமிழ் APP-ல</span></h1>
                                {/* <p>27க்கும் மேற்பட்ட பிரிவுகளில், 1000 கணக்கான புத்தங்கள் நமது மொழயில்!</p> */}
                                <p>27க்கும் மேற்பட்ட பிரிவுகளில், நூற்றுக்கணக்கான புத்தங்கள் நமது மொழியில்!</p>
                                {/* <form action="!#">
                            <div className="input-box">
                                <input type="text" placeholder="info@appie.com"/>
                                <i className="fal fa-envelope"></i>
                                <button><i className="fal fa-arrow-right"></i></button>
                            </div>
                        </form>
                        <div className="hero-users">
                            <img src="assets/images/hero-mans.png" alt=""/>
                            <span>30k <span> Feedback</span></span>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="appie-hero-thumb-3 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                    <img src="assets/images/hero-thumb-3-1.png" alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src="assets/images/shape/shape-9.png" alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src="assets/images/shape/shape-10.png" alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src="assets/images/shape/shape-11.png" alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src="assets/images/shape/shape-12.png" alt="" />
                </div>
            </section>



            <section className="appie-services-2-area pb-100" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                {/* <h3 className="appie-title">MagicTamil எப்படி வேலை செய்கிறது என்று யோசிக்கிறீர்களா? </h3>
                                <p>Diversify your Knowledge with MagicTamil’s User-Friendly Podcast Features! </p> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            {/* <div className="appie-section-title text-right">
                                <a className="main-btn" href="!#">View all Features <i className="fal fa-arrow-right"></i></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="icon">
                                    <i className="fas fa-sign-in"></i>
                                </div>
                                <h4 className="title">எளிமையான முறை </h4>
                                <p>With just a Mobile Number or Email Address, Users can Log In Hassle-Free and Start Exploring!</p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div className="icon">
                                    <i className="fas fa-layer-group"></i>
                                </div>
                                <h4 className="title">27+ பிரிவுகள் </h4>
                                <p>Explore Different Categories in One Place and Widen your Knowledge!</p>
                                <br />

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div className="icon">
                                    <i className="fas fa-books"></i>
                                </div>
                                <h4 className="title">எனது நூலகம்</h4>
                                <p>Found what you were Looking for? Add to the Library and Listen Whenever, Wherever!</p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="icon">
                                    <i className="fas fa-user"></i>
                                </div>
                                <h4 className="title">படைப்பாளரைச் சந்திக்கவும்</h4>
                                <p>Dive In and Find the Unique Creators with Unimaginable Philosophical or Logical Approach!</p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div className="icon">
                                    <i className="fas fa-wifi-slash"></i>
                                </div>
                                <h4 className="title">ஆஃப்லைன் பயன்முறை</h4>
                                <p>Listen to Podcasts without the Need for Wi-Fi or Internet Connection. Get your Airplane Mode Covered!</p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div className="icon">
                                    <i className="fas fa-share"></i>
                                </div>
                                <h4 className="title">பாட்காஸ்ட்டைப் பகிரவும்</h4>
                                <p>Invite Friends and Share your Highly Recommended Podcasts! A Friend in need is a Friend Indeed!</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="appie-about-area mb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-box wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="about-thumb">
                                            <img src="assets/images/about-thumb-1.png" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="appie-about-content">
                                            <span>Marketing</span>
                                            <h3 className="title">Revolutionize Learning with MagicTamil</h3>
                                            <p>Create your Own Playlist along with Discover the Recommended Podcasts!</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check"></i>
                                                    </div>
                                                    <h4 className="title">Curated for You</h4>
                                                    <p>Get recommendations based on Recently Played Podcasts!</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="appie-about-service mt-30">
                                                    <div className="icon">
                                                        <i className="fal fa-check"></i>
                                                    </div>
                                                    <h4 className="title">Sync Highlights</h4>
                                                    <p>Synchronize your Highlights Seamlessly with MagicTamil!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="appie-features-area-2 pt-90 pb-100" id="features">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="appie-section-title appie-section-title-2 text-center">
                                <h3 className="appie-title">அடுத்த தலைமுறைக்கான வழிகாட்டி வலையொளிகளை கேளுங்க மேஜிக் தமிழ் App உடன்!</h3>
                                {/* <p>Leave behind the Reading Process and Listen to More Podcasts in Less Time!  </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-features-boxes">
                                <div className="appie-features-box-item">
                                    <h4 className="title">User-Friendly features: </h4>
                                    <p>Easy-to-use interface especially for You!</p>
                                </div>
                                <div className="appie-features-box-item item-2">
                                    <h4 className="title">Smooth Navigation</h4>
                                    <p>Don’t Waste Time and Get Smooth Navigation!</p>
                                </div>
                                <div className="appie-features-box-item item-3">
                                    <h4 className="title">Dual Mode</h4>
                                    <p>Choose from Dual Modes- Light or Dark!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-features-thumb wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <img src="assets/images/features-thumb-2-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-shape-1">
                    <img src="assets/images/shape/shape-15.png" alt="" />
                </div>
                <div className="features-shape-2">
                    <img src="assets/images/shape/shape-14.png" alt="" />
                </div>
                <div className="features-shape-3">
                    <img src="assets/images/shape/shape-13.png" alt="" />
                </div>
            </section>



            {/* <section className="appie-counter-area pt-90 pb-190">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Trusted Podcast Application of All Time!</h3>
                                <p>The full monty spiffing good time no biggie cack grub fantastic. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="assets/images/ico/counter-icon-1.90.delay" alt="" />
                                    </div>
                                    <h3 className="title"><span className="counter-item">{categoryCount}</span>+</h3>
                                    <p>Diverse Categories</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="assets/images/ico/counter-icon-2.91.delay" alt="" />
                                    </div>
                                    <h3 className="title"><span className="counter-item">{authorCount}</span>+</h3>
                                    <p>Active Creators</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="assets/images/ico/counter-icon-3.92.delay" alt="" />
                                    </div>
                                    <h3 className="title"><span className="counter-item">{userCount}</span>+</h3>
                                    <p>Listening Users</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="appie-single-counter mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                <div className="counter-content">
                                    <div className="icon">
                                        <img src="assets/images/ico/counter-icon-4.93.delay" alt="" />
                                    </div>
                                    <h3 className="title"><span className="counter-item">{upcomingPodcastCount}</span>+</h3>
                                    <p>Upcoming Podcasts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}




            {/* <section className="appie-video-player-area pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-video-player-item">
                                <div className="thumb">
                                    <img src="assets/images/video-thumb-1.jpg" alt="" />
                                    <div className="video-popup">
                                        <a className="appie-video-popup" href="https://www.youtube.com/watch?v=EE7NqzhMDms"><i className="fas fa-play"></i></a>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3 className="title">Watch the two-minute video to learn how.</h3>
                                    <p>Simplified interface to ensure Hassle-Free Navigation through the MagicTamil! To understand better, watch the application tutorial!</p>
                                    <a className="main-btn" href="https://web.magic20.co.in/#/intro">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="appie-video-player-slider">
                                <div className="item">
                                    <img src="assets/images/video-slide-1.jpg" alt="" />
                                </div>
                                <div className="item">
                                    <img src="assets/images/video-slide-2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            <section id="download" className="appie-download-area pt-150 pb-160 mb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-download-content">
                                <span>Download Our App</span>
                                <h3 className="title">Download MagicTamil <br /> for Free Today!</h3>
                                {/* <p>Bless your Ears and Mind with the Podcasts from Top-Notch Creators!</p> */}
                                <ul>
                                    <li>
                                        <a href="https://apps.apple.com/in/app/magic-20-tamil/id6444919423" target='_blank' rel="noreferrer">
                                            <i className="fab fa-apple"></i>
                                            <span>Download for <span>iOS</span></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="https://play.google.com/store/apps/details?id=com.app.puthagam" target='_blank' rel="noreferrer">
                                            <i className="fab fa-google-play"></i>
                                            <span>Download for <span>Android</span></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-shape-1">
                    <img src="assets/images/shape/shape-15.png" alt="" />
                </div>
                <div className="download-shape-2">
                    <img src="assets/images/shape/shape-14.png" alt="" />
                </div>
                <div className="download-shape-3">
                    <img src="assets/images/shape/shape-13.png" alt="" />
                </div>
            </section>


            <section className="sec bg-light-blue">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-start flex-wrap'>
                                {
                                    bookList.map((item, i) =>
                                        <button className={item.categoryName === massage ? "btn-list active" : "btn-list"}
                                            onClick={(e) => {
                                                console.log("Item books:", item.books);
                                                setBook(prevBooks => item.books);
                                                setMassage(item.categoryName)
                                                setBookCount(item.books.length)
                                            }}> {item.categoryName}</button>
                                    )
                                }
                            </div>
                            <hr />
                            <div className="appie-section-title">
                                <h3 className="appie-title">{massage}</h3>
                                <p>Read or listen to {bookCount} titles in this category</p>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 list-slider">
                            {/* <ul>
                                {
                                    book?.map((item) =>
                                        <li>{item.title}</li>
                                    )
                                }
                            </ul> */}
                            <Swiper
                                slidesPerView={6}
                                spaceBetween={30}
                                navigation={true}
                                pagination={true}
                                mousewheel={true}
                                keyboard={true}
                                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                className="mySwiper"
                            >
                                {
                                    book?.map((item) =>
                                        <SwiperSlide>
                                            <div className="nav-tabs-custom pull-left">
                                                <div className="card-img">
                                                    <img src={item.image} alt={item.title} title={item.title} />
                                                </div>
                                                <div className="text-center card_txt">
                                                    <h2 className="title_txt">{item.title}                      </h2>
                                                    <h2 className="pub_txt">{item.categoryName}                      </h2>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }


                            </Swiper>

                            {/* <OwlCarousel className='owl-theme common_wd  owl-theme' loop margin={10} items={6} dots={false} nav>
                                {
                                    book?.map((item) =>

                                        <div className="item" key={item}>
                                            <a href="https://web.magic20.co.in/">
                                                <div className="nav-tabs-custom pull-left">
                                                    <div className="card-img">
                                                        <img src={item.image} alt={item.title} title={item.title} />
                                                    </div>
                                                    <div className="text-center card_txt">
                                                        <h2 className="title_txt">{item.title}                      </h2>
                                                        <h2 className="pub_txt">{item.categoryName}                      </h2>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }
                            </OwlCarousel> */}

                        </div>
                    </div>
                </div>
            </section>



            <section className="appie-testimonial-2-area mb-90 mt-100" id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Price</h3>
                            </div>
                            <div className="tabed-content">
                                <div id="month">
                                    <div className="row justify-content-center">
                                        {
                                            price.map((item) =>
                                                countrys !== "India" ? "" : item.item.currency === "INR" ?
                                                    <div className="col-lg-4 col-md-6 wow animated fadeInLeft" style={{ marginBottom: '15px' }}>
                                                        <div className="pricing-one__single" style={{ border: '1px solid #e1e4ea' }}>
                                                            <div className="pricig-heading">
                                                                <h6>{item.item.name}</h6>
                                                                <div className="price-range"><sup>{item.item.currency}</sup> {item.originalAmount === 0 || item.originalAmount === item.item.amount ? "" : <span><del>{item.originalAmount}</del> / </span>}<span>{item.item.amount}</span></div>

                                                            </div>
                                                            {/* <div className="pricig-body">
                                                                <p>{item.item.description}</p>
                                                                <div className="pricing-btn mt-35">
                                                                <a className="main-btn" href="#">Choose plan</a>
                                                            </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    : ""
                                            )
                                        }


                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>




            {/* <section className="appie-testimonial-2-area mb-90" id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-testimonial-2-box">
                                <div className="appie-testimonial-slider-2">
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src="assets/images/testimonial-user-1.97.delay" alt="" />
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span>(4.7) review</span>
                                            </div>
                                            <div className="content">
                                                <p>Why I say old chap that is spiffing chip shop such a fibber the bee's knees, the wireless Richard fantastic do one cracking goal pukka baking cakes starkers mush don't get shirty with me argy bargy, I naff chimney pot blimey he lost his bottle cup.</p>
                                                <div className="author-info">
                                                    <h5 className="title">Hanson Deck</h5>
                                                    <span>Web developer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="appie-testimonial-slider-2-item">
                                        <div className="item">
                                            <div className="thumb">
                                                <img src="assets/images/testimonial-user-1.97.delay" alt="" />
                                                <ul>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                    <li><i className="fas fa-star"></i></li>
                                                </ul>
                                                <span>(4.7) review</span>
                                            </div>
                                            <div className="content">
                                                <p>Why I say old chap that is spiffing chip shop such a fibber the bee's knees, the wireless Richard fantastic do one cracking goal pukka baking cakes starkers mush don't get shirty with me argy bargy, I naff chimney pot blimey he lost his bottle cup.</p>
                                                <div className="author-info">
                                                    <h5 className="title">Hanson Deck</h5>
                                                    <span>Web developer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* <section className="appie-footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget footer-about-widget-2">
                                <div className="logo">
                                    <a href="!#"><img src="assets/images/fav.png" alt="" style={{ mixBlendMode: "darken", }} /></a>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation footer-navigation-2">
                                <h4 className="title">Categories</h4>
                                {<ul>
                                    {
                                        categories.map((item) =>
                                            <li key={item._id}>{item.name}</li>

                                        )
                                    }

                                </ul>}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation footer-navigation-2 footer-about-widget" style={{ paddingRight: '50px' }}>
                                <h4 className="title">Important Links</h4>
                                <ul>

                                    <li><Link to='privacypolicy'>Privacy Policy</Link></li>
                                    <li><Link to='Terms&Conditions'>Terms & Conditions</Link></li>
                                    <li><Link to='RefundAndCancellation'>Refund & Cancellation Policy</Link></li>
                                </ul>
                                <div className="social mt-30">
                                    <ul>
                                        <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100083953600943"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a target='_blank' href="https://twitter.com/Booksparkmedia"><i className="fab fa-twitter"></i></a></li>
                                        <li><a target='_blank' href="https://www.instagram.com/magictamil20/"><i class="fab fa-instagram"></i></a></li>
                                        <li><a target='_blank' href="https://www.linkedin.com/company/magictamil20/mycompany/"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a target='_blank' href="https://www.youtube.com/@magictamil20"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li><a href="!#"><i className="fal fa-envelope"></i> support@magic20.co.in</a></li>
                                    <li><a href="!#"><i className="fal fa-phone"></i> +91 9176346063</a></li>
                                    <li><a href="!#"><i className="fal fa-map-marker-alt"></i> Bookspark Media Pvt Ltd,4th Floor, 37/9 Hariram Building,  College road, Chennai 600006</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li><a href="!#"><i className="fab fa-apple"></i> Download for iOS</a></li>
                                        <li><a className="item-2" href="!#"><i className="fab fa-google-play"></i> Download for Android</a></li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2023 MagicTamil. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


        </div>
    );
}

export default Home;
