import { Link } from 'react-router-dom'
import logo from './magic.png'
const Header =()=>{
    
    return(
        <>
        <header className="appie-header-area appie-header-2-area appie-sticky">
                <div className="container-fluid">
                    <div className="header-nav-box">
                        <div className="row align-items-flexstart">
                            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <a href="/">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-1 col-sm-1 order-3 order-sm-2">
                            <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">

</div>

                            </div>
                            <div className="col-lg-5  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right" style={{display:"flex",justifyContent:"flex-end"}}>
                                <div class="appie-header-main-menu">
                                    <ul>
                                        <li className='appie-btn-box '>
                                            <span  className='main-btn  text-white'>Events <i class="fal fa-angle-down"></i></span>
                                            <ul class="sub-menu">
                                                <li><Link to="/PastEvents">Past Events</Link></li>
                                                <li><Link to="/upcomingEvent">Upcoming Events</Link></li>
                                            </ul>
                                        </li>
                                        <li className='appie-btn-box '> <a className="main-btn  text-white" href="https://web.magic20.co.in/#/intro">Get Started</a></li>
                                        <li className='appie-btn-box '> <a className="main-btn text-white" href="#download">Download Our App</a></li>
                                    </ul>
                                </div>
                                    {/* <a className="main-btn ml-30 text-white" href="https://web.magic20.co.in/#/intro">Get Started</a>
                                    <a className="main-btn ml-30 text-white" href="#download">Download Our App</a>
                                    <div className="toggle-btn ml-30 canvas_open d-lg-none d-block">
                                        <i className="fa fa-bars"></i>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header