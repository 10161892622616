import React, { useEffect, useState } from 'react';
import Header from '../header';
import { useParams } from 'react-router-dom';
import EventsApi from '../baseUrl/services';
import dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal';
import { Alert, Col, Form, Row } from "react-bootstrap";
import Swal from 'sweetalert2';

function EventDetails() {
  const {eventId} = useParams()
  console.log("eventId",eventId)
  const [data,setData] = useState([])

  function eventDetail(){
      EventsApi.eventsDetail(eventId).then((response)=>{
          console.log("passEvent",response.data)
          setData(response.data)
       })
  }

  useEffect(()=>{
    eventDetail() // eslint-disable-next-line
  },[])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [contactValidated, setContactValidated] = useState(false);

  const messsageDataSchema = {
    EventId:eventId,
    Name:"",
    Email:"",
    Phone:"",
  }
  const [messsageData,setMesssageData] = useState(messsageDataSchema)
  const [error,setError] = useState(false)
  function typeMessage(event) {
    setError(false)
    const { name, value } = event.target;
    const phoneRegex = /^[0-9\b]+$/;
    // Check if the input value matches the phone number regex
      if (
        name === "Phone"
      ) {
        if (phoneRegex.test(value) || value === "") {
          setMesssageData({ ...messsageData, [name]: value });
        }
      } else {
        setMesssageData({ ...messsageData, [name]: value });
      };
    
  
  }

  const [errorText,setErrorText] = useState("")
  function sendMessage(){
    EventsApi.eventsRegistration(messsageData).then((response)=>{
      console.log("response",response)
      setShow(false);
    Swal.fire({
      // position: "top-end",
      icon: "success",
      title: "Registration successfully!",
      showConfirmButton: false,
      timer: 1500,
    });
    }).catch((error)=>{
      setError(true)
      setErrorText(error.response.data)
      console.log("error",error.response.data)
    })
    
    
  }
  const contactFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
     if(messsageData.name !== "" && messsageData.email !== "" && messsageData.phoneNumber !== ""){
      sendMessage()
     }
    }
    
    setContactValidated(true);
  };
    return (
        <>
        <Header/>
        <section class="appie-hero-area-2 mb-90" style={{paddingTop:"230px"}}>
                <div class="container">
                    <div class="row align-items-center">
                        <div className="col-lg-4">
                            <div className="appie-page-title-item">
                               
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" style={{ color: '#0e1133' }}>Home</li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ color: '#0e1133' }}>Past Events</li>
                                    </ol>
                                </nav>
                                <h3 className="title" style={{ color: '#0e1133' }}>{data.eventName}</h3>
                                {/* <div className="thumb">
                                    <img src="/assets/images/signup-thumb.png" alt="" />
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                        <div className="appie-hero-thumb-3 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms" style={{width:"100%",position:"relative"}}>
                    <img src={data.thumbnailUrl}  style={{objectFit:"cover",objectPosition:"center center",width:"100%",height:"320px"}} alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src="/assets/images/shape/shape-9.png" alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src="/assets/images/shape/shape-10.png" alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src="/assets/images/shape/shape-11.png" alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src="/assets/images/shape/shape-12.png" alt="" />
                </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section class="appie-service-details-area pt-0 pb-100">
   <div class="container">
      <div class="row">
         <div class="col-lg-8">
            <div class="service-details-content">
               {/* <div class="thumb"><img src="https://drsw10gc90t0z.cloudfront.net/AcuCustom/Sitename/DAM/492/Spotifys_audiobooks_event_in_New_York.jpg" alt=""/></div> */}
               <div class="content" dangerouslySetInnerHTML={{__html:data.description}}>
                  
               </div>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="service-details-sidebar mr-50">
               <div class="service-category-widget">
                  <ul>
                     <li><i class="fal fa-calendar"></i>{dayjs(data.fromDate).format("DD/MMM/YYYY")} - {dayjs(data.toDate).format("DD/MMM/YYYY")}</li>
                     <li style={{display:"flex",alignItems:"flex-start"}}>{data.onlineEvent ? <i className="fas fa-podcast"></i> : <i className="fal fa-map-pin"></i>}<span style={{marginTop:"-8px"}}>{data.platformOrVenue}</span></li>
                     <li><i class="fal fa-user-friends"></i>{data.participantsCount} Delegates </li>
                     {/* <li><i class="fal fa-bookmark"></i>Appie Template</li>
                     <li><i class="fal fa-globe"></i>quomodosoft.com/appie</li> */}
                  </ul>
               </div>
               {
                data.registrationEnabled ? <div class="appie-section-title text-right w-100"><button class="main-btn w-100"  style={{background: "linear-gradient(90deg, rgba(0, 30, 58, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(0, 20, 45, 1) 100%)",color:"#fff"}} onClick={handleShow}>Register Now <i class="fal fa-arrow-right"></i></button></div> : null
               }
               
               {/* <div class="service-download-widget"><a href="#"><i class="fal fa-sign"></i><span>Download docs</span> <i class="fal fa-arrow-right"></i></a></div>
               <div class="service-download-widget"><a href="#"><i class="fal fa-file-pdf"></i><span>Characteristics</span></a></div> */}
            </div>
         </div>
      </div>
   </div>
</section>

{/* <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-lg">
  <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Registration for Event</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Name:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Email:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Phone:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div> */}

<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Event Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <form>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Name:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Email:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Phone:</label>
            <input type="text" class="form-control" id="recipient-name"/>
          </div>
        </form> */}
         <Form noValidate validated={contactValidated}>
      <Row className="mb-0">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="Name"
            maxLength="50"
            onChange={(e)=>typeMessage(e)}
          />
          
        </Form.Group> 
        <Form.Group as={Col} md="12" controlId="validationCustom01" className="mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="Email"
            name="Email"
            maxLength="50"
            onChange={(e)=>typeMessage(e)}
          />
          
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="validationCustom01"  className="mt-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            required
            type="text"
            name="Phone"
            minLength="10"
            maxLength="15"
            value={messsageData.Phone}
            onChange={(e)=>typeMessage(e)}
          />
          {
            error ?
            <Alert key={"danger"} variant={"danger"} className='mt-3 mb-0'>
          {errorText}
        </Alert> : null
          }
        </Form.Group>
        
      </Row>
    
      {/* <button class="btn btn-primary login-btn height-50 w-100" onClick={contactFormSubmit}>Submit</button> */}
     
    </Form>
        </Modal.Body>
        <Modal.Footer>

          <div class="appie-section-title text-right w-100" style={{paddingBottom:0}}><button class="main-btn w-100"  style={{background: "linear-gradient(90deg, rgba(0, 30, 58, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(0, 20, 45, 1) 100%)",color:"#fff"}} onClick={contactFormSubmit}>Register Now <i class="fal fa-arrow-right"></i></button></div>
        </Modal.Footer>
      </Modal>
        </>
    );
}

export default EventDetails;
