import React, { useEffect, useState } from 'react';
import Header from '../header.jsx';
import EventsApi from '../baseUrl/services.jsx';
import Events from './eventList.jsx';
function PastEvents() {
    const [data,setData] = useState([])

    function passEvent(){
        EventsApi.pastEvent().then((response)=>{
            console.log("passEvent",response)
            setData(response)
         })
    }

    useEffect(()=>{
        passEvent()
    },[])
    return (
        <>
<Header/>



            <section class="appie-hero-area-2 mb-90">
                <div class="container">
                    <div class="row align-items-center">
                        <div className="col-lg-12">
                            <div className="appie-page-title-item" style={{marginBottom:50}}>
                                <h3 className="title" style={{ color: '#0e1133' }}>Past Events</h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" style={{ color: '#0e1133' }}>Home</li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ color: '#0e1133' }}>Past Events</li>
                                    </ol>
                                </nav>
                                <div className="thumb">
                                    <img src="assets/images/signup-thumb.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="appie-hero-thumb-3 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                    <img src="assets/images/hero-thumb-3-1.png" alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src="assets/images/shape/shape-9.png" alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src="assets/images/shape/shape-10.png" alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src="assets/images/shape/shape-11.png" alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src="assets/images/shape/shape-12.png" alt="" />
                </div>
            </section>

            <section className="blogpage-section" style={{paddingTop:"0"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-7">
                            <Events data={data}/>
                        </div>
                        {/* <div className="col-lg-4 col-md-5">
                            <BlogSideBar />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PastEvents;
