import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';


function Events(prop) {
    const data = prop.data.data
    console.log("Events Prop",data)
    return (
        <>
                       <div className="row">
                       {
                            data?.map((item)=>
                                <div className="col-lg-4">
                            <div className="post-item-1">
                                <img src={item.thumbnailUrl} alt="" />
                                <div className="b-post-details">
                                    <div className="bp-meta">
                                        <a href="/" style={{textDecoration:"none"}}>
                                            <i className="fal fa-calendar"></i>{dayjs(item.fromDate).format("DD/MMM/YYYY")}
                                        </a>
                                        <a href="/" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"230px",textDecoration:"none"}}>
                                           {item.onlineEvent ? <i className="fas fa-podcast"></i> : <i className="fal fa-map-pin"></i>} {item.platformOrVenue}
                                        </a>
                                    </div>
                                    <h3>
                                        <Link
                                         to={`${'/eventDetails/'}${item.eventId}`}
                                       //  to="/eventDetails"
                                       style={{textDecoration:"none"}}
                                         >
                                        {item.eventName}
                                        </Link>
                                    </h3>
                                    <Link className="read-more" to={`${'/eventDetails/'}${item.eventId}`} style={{textDecoration:"none"}}>
                                        Read More<i className="fal fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                            )
                        }
                {/* <div className="col-lg-4">
                    <div className="post-item-1">
                        <img src={"https://drsw10gc90t0z.cloudfront.net/AcuCustom/Sitename/DAM/492/Spotifys_audiobooks_event_in_New_York.jpg"} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="/">
                                    <i className="fal fa-calendar"></i>May 22, 2024
                                </a>
                                <a href="/">
                                    <i className="fal fa-map-pin"></i>Hariram Hall, Chennai
                                </a>
                            </div>
                            <h3>
                                <Link to="/eventDetails">
                                2 Days Workshop on Building Your First Website using WordPress
                                </Link>
                            </h3>
                            <a className="read-more" href="single-post.html">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="post-item-1">
                        <img src={"https://image.isu.pub/210801154433-1ae9eb534f3f86f6ae8098dbeec3c9aa/jpg/page_1.jpg"} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="/">
                                    <i className="fal fa-clock"></i>April 22, 2020
                                </a>
                                <a href="/">
                                    <i className="fas fa-podcast"></i> Youtube
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                Tamil Mandram – தமிழ் மன்றம்
                                </a>
                            </h3>
                            <a className="read-more" href="/news/single-news">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="post-item-1">
                        <img src={"https://www.calcuttayellowpages.com/cimage35/112239college_01.jpg"} alt="" />
                        <div className="b-post-details">
                            <div className="bp-meta">
                                <a href="/">
                                <i className="fal fa-calendar"></i>April 22, 2020
                                </a>
                                <a href="/">
                                <i className="fal fa-map-pin"></i>Hariram Hall, Chennai
                                </a>
                            </div>
                            <h3>
                                <a href="/news/single-news">
                                கோயம்புத்தூர் ஃப்ரீலான்சர்ஸ் மாதாந்திர சந்திப்பு – மே 2024
                                </a>
                            </h3>
                            <a className="read-more" href="/news/single-news">
                                Read More<i className="fal fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div> */}

            </div>
            {/* <div className="row">
                <div className="col-lg-12">
                    <div className="bisylms-pagination">
                        <span className="current">01</span>
                        <a href="/">02</a>
                        <a className="next" href="/">
                            next<i className="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Events;
